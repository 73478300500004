import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container } from '@mui/material';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Admin Visa Monde Sociétés
          </Typography>
          <Button color="inherit" component={Link} to="/demandes" sx={{ color: '#fff' }}>
            Demandes
          </Button>
          <Button color="inherit" component={Link} to="/facturation" sx={{ color: '#fff' }}>
            Facturation
          </Button>
          <Button color="inherit" component={Link} to="/pays" sx={{ color: '#fff' }}>
            Pays
          </Button>
          <Button color="inherit" component={Link} to="/visas" sx={{ color: '#fff' }}>
            Visas
          </Button>
          <Button color="inherit" component={Link} to="/utilisateurs" sx={{ color: '#fff' }}>
            Utilisateurs
          </Button>
          <Button color="inherit" component={Link} to="/societes" sx={{ color: '#fff' }}>
            Sociétés
          </Button>
         
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Home;
