import React, { useEffect, useState } from 'react';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, MenuItem } from '@mui/material';
import { recupereListeDemandes } from '../Services';
import { Demandes } from '../models/Demandes';
import DemandeActions from './DemandeActions';

export const ListeDemandes: React.FC<{ onSelectionDemande: (idVoyageur: number, chemin: string) => void }> = ({ onSelectionDemande }) => {
  const [demandes, setDemandes] = useState<Demandes[]>([]);
  const [filteredDemandes, setFilteredDemandes] = useState<Demandes[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [actionsOpen, setActionsOpen] = useState(false); // Pour contrôler l'ouverture de DemandeActions
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [nameSearch, setNameSearch] = useState('');
  const [emailSearch, setEmailSearch] = useState('');
  const [societeFilter, setSocieteFilter] = useState('');
  const [groupeFilter, setGroupeFilter] = useState('');

  const handleRowClick = (id: number, idVoyageur: number, chemin: string) => {
    setSelectedId(id); // Met à jour l'ID sélectionné pour la mise en évidence
    if (selectedId !== null) {
      onSelectionDemande(idVoyageur, chemin);
    }
  };

  useEffect(() => {
    const fetchDemandes = async () => {
      try {
        const result = await recupereListeDemandes();
        setDemandes(result);
        setFilteredDemandes(result);
      } catch (error) {
        console.error('Erreur lors de la récupération des demandes:', error);
      }
    };

    fetchDemandes();
  }, []);

  useEffect(() => {
    filterDemandes();
  }, [nameSearch, emailSearch, societeFilter, groupeFilter]);

  const reloadDemandes = async () => {
    try {
      const result = await recupereListeDemandes();
      setDemandes(result);
      setFilteredDemandes(result);
      setSelectedIds([]); // Décoche toutes les cases
      setSelectedEmail(null); // Réinitialise l'email sélectionné
    } catch (error) {
      console.error('Erreur lors de la récupération des demandes:', error);
    }
  };

  const filterDemandes = () => {
    let filtered = demandes;
    if (nameSearch) {
      filtered = filtered.filter(demande => demande.nom.toLowerCase().includes(nameSearch.toLowerCase()));
    }
    if (emailSearch) {
      filtered = filtered.filter(demande => demande.email.toLowerCase().includes(emailSearch.toLowerCase()));
    }
    if (societeFilter) {
      filtered = filtered.filter(demande => demande.societe === societeFilter);
    }
    if (groupeFilter) {
      filtered = filtered.filter(demande => demande.groupe === groupeFilter);
    }
    setFilteredDemandes(filtered);
  };

  function formatDate(isoString: any) {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const handleCheckboxChange = (demande: Demandes) => {
    if (demande.id !== null) { // Assurez-vous que l'ID n'est pas null
      if (selectedEmail === null || selectedEmail === demande.email) {
        if (selectedIds.includes(demande.id)) {
          setSelectedIds(selectedIds.filter(id => id !== demande.id));
          if (selectedIds.length <= 1) {
            setSelectedEmail(null); // Réinitialiser l'email sélectionné si aucune case n'est cochée
          }
        } else {
          setSelectedIds([...selectedIds, demande.id]);
          setSelectedEmail(demande.email);
        }
      }
    }
  };

  const handleOpenActions = () => {
    if (selectedIds.length > 0) { // Assurez-vous qu'au moins une case est cochée
      setActionsOpen(true);
    }
  };

  const handleCloseActions = () => {
    setActionsOpen(false);
  };

  const uniqueSocietes = Array.from(new Set(demandes.map(demande => demande.societe)));
  const uniqueGroupes = Array.from(new Set(demandes.map(demande => demande.groupe)));

  return (
    <>
      <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
        <TextField
          label="Recherche par Nom"
          value={nameSearch}
          onChange={(e) => setNameSearch(e.target.value)}
          style={{ minWidth: '200px' }}
        />
        <TextField
          label="Recherche par Email"
          value={emailSearch}
          onChange={(e) => setEmailSearch(e.target.value)}
          style={{ minWidth: '200px' }}
        />
        <TextField
          select
          label="Filtrer par Société"
          value={societeFilter}
          onChange={(e) => setSocieteFilter(e.target.value)}
          style={{ minWidth: '200px' }}
        >
          <MenuItem value="">
            <em>Toutes</em>
          </MenuItem>
          {uniqueSocietes.map((societe) => (
            <MenuItem key={societe} value={societe}>
              {societe}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Filtrer par Groupe"
          value={groupeFilter}
          onChange={(e) => setGroupeFilter(e.target.value)}
          style={{ minWidth: '200px' }}
        >
          <MenuItem value="">
            <em>Tous</em>
          </MenuItem>
          {uniqueGroupes.map((groupe) => (
            <MenuItem key={groupe} value={groupe}>
              {groupe}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenActions}
        disabled={selectedIds.length === 0}
        style={{ marginBottom: '20px' }}
      >
        Actions
      </Button>
      <TableContainer component={Paper} style={{ maxHeight: '650px' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell> {/* Ajout d'une colonne pour les cases à cocher */}
              <TableCell>Nº</TableCell>
              <TableCell>Date demande</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Titre</TableCell>
              <TableCell>Date de départ</TableCell>
              <TableCell>Paiement</TableCell>
              <TableCell>Etat demande</TableCell>
              <TableCell>Assurance</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Alerte</TableCell>
              <TableCell>Société</TableCell> {/* Nouvelle colonne */}
              <TableCell>Groupe</TableCell>   {/* Nouvelle colonne */}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDemandes.map((demande) => (
              <TableRow
                key={demande.id}
                onClick={() => handleRowClick(demande.id!, demande.id_voyageur, demande.chemin)}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  backgroundColor: selectedId === demande.id ? '#ADD8E6' : 'inherit',
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedIds.includes(demande.id!)}
                    onChange={() => handleCheckboxChange(demande)}
                    disabled={selectedEmail !== null && selectedEmail !== demande.email}
                  />
                </TableCell>
                <TableCell>{demande.id}</TableCell>
                <TableCell>{formatDate(demande.date_creation)}</TableCell>
                <TableCell>{demande.email}</TableCell>
                <TableCell>{demande.titre}</TableCell>
                <TableCell>{demande.datedepart}</TableCell>
                <TableCell>{demande.etat_paiement}</TableCell>
                <TableCell>{demande.etat_visa}</TableCell>
                <TableCell>{demande.assurancevisa}</TableCell>
                <TableCell>{demande.infosdemande}</TableCell>
                <TableCell>{demande.alerte}</TableCell>
                <TableCell>{demande.societe}</TableCell> {/* Nouvelle donnée */}
                <TableCell>{demande.groupe}</TableCell>   {/* Nouvelle donnée */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DemandeActions open={actionsOpen} onValidated={reloadDemandes} onClose={handleCloseActions} selectedIds={selectedIds} selectedEmail={selectedEmail} />
    </>
  );
};
