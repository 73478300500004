import axios from 'axios';

const API_URL = 'https://adminvm-gg-be.visamonde.com';

export const getUtilisateursAdmin = async () => {
  const response = await axios.get(`${API_URL}/utilisateurs_admin`);
  return response.data;
};

export const createUtilisateurAdmin = async (utilisateur) => {
  const response = await axios.post(`${API_URL}/utilisateurs_admin`, utilisateur);
  return response.data;
};

export const updateUtilisateurAdmin = async (id, utilisateur) => {
  const response = await axios.put(`${API_URL}/utilisateurs_admin/${id}`, utilisateur);
  return response.data;
};

export const deleteUtilisateurAdmin = async (id) => {
  const response = await axios.delete(`${API_URL}/utilisateurs_admin/${id}`);
  return response.data;
};

export const getUtilisateursBySociete = async (idSociete) => {
  const response = await axios.get(`${API_URL}/utilisateurs_admin?societe=${idSociete}`);
  return response.data;
};