import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getSocietes, createSociete, updateSociete, deleteSociete } from '../services/societes';
import { getUtilisateursAdmin, createUtilisateurAdmin, updateUtilisateurAdmin, deleteUtilisateurAdmin, getUtilisateursBySociete } from '../services/utilisateursAdmin';
import Home from './Home';

const SocietesCrud = () => {
  const [societes, setSocietes] = useState([]);
  const [utilisateurs, setUtilisateurs] = useState([]);
  const [selectedSociete, setSelectedSociete] = useState(null);
  const [selectedSocieteUtilisateurs, setSelectedSocieteUtilisateurs] = useState([]);
  const [openSocieteDialog, setOpenSocieteDialog] = useState(false);
  const [openUtilisateurDialog, setOpenUtilisateurDialog] = useState(false);
  const [newSociete, setNewSociete] = useState({ nom: '', mail: '', paiementCB: 'Non' });
  const [newUtilisateur, setNewUtilisateur] = useState({ nom: '', email: '', mot_de_passe: '', id_societe: '', droit: 'superadmin' });

  const fetchSocietes = useCallback(async () => {
    const data = await getSocietes();
    setSocietes(data);
  }, []);

  const fetchUtilisateurs = useCallback(async () => {
    const data = await getUtilisateursAdmin();
    setUtilisateurs(data);
  }, []);

  useEffect(() => {
    fetchSocietes();
    fetchUtilisateurs();
  }, [fetchSocietes, fetchUtilisateurs]);

  const handleOpenSocieteDialog = (societe = null) => {
    setSelectedSociete(societe);
    setNewSociete(societe || { nom: '', mail: '', paiementCB: 'Non' });
    setOpenSocieteDialog(true);
  };

  const handleCloseSocieteDialog = () => {
    setOpenSocieteDialog(false);
    setNewSociete({ nom: '', mail: '', paiementCB: 'Non' });
  };

  const handleSocieteChange = (e) => {
    const { name, value } = e.target;
    setNewSociete({ ...newSociete, [name]: value });
  };

  const handleSaveSociete = async () => {
    if (selectedSociete) {
      await updateSociete(selectedSociete.id, { ...newSociete, paiementCB: newSociete.paiementCB === 'Oui' });
    } else {
      await createSociete({ ...newSociete, paiementCB: newSociete.paiementCB === 'Oui' });
    }
    fetchSocietes();
    handleCloseSocieteDialog();
  };

  const handleDeleteSociete = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette société ?')) {
      await deleteSociete(id);
      fetchSocietes();
    }
  };

  const handleUtilisateurChange = (e) => {
    const { name, value } = e.target;
    setNewUtilisateur({ ...newUtilisateur, [name]: value });
  };

  const handleUtilisateurSave = async () => {
    if (newUtilisateur.id_utilisateur) {
      await updateUtilisateurAdmin(newUtilisateur.id_utilisateur, newUtilisateur);
    } else {
      await createUtilisateurAdmin({ ...newUtilisateur, id_societe: selectedSociete.id });
    }
    fetchUtilisateurs();
    handleCloseUtilisateurDialog();
    handleSocieteClick(selectedSociete);
  };

  const handleDeleteUtilisateur = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')) {
      await deleteUtilisateurAdmin(id);
      fetchUtilisateurs();
      handleSocieteClick(selectedSociete);
    }
  };

  const handleSocieteClick = async (societe) => {
    setSelectedSociete(societe);
    const utilisateursData = await getUtilisateursBySociete(societe.id);
    setSelectedSocieteUtilisateurs(utilisateursData);
  };

  const handleOpenUtilisateurDialog = (utilisateur = null) => {
    setNewUtilisateur(utilisateur || { nom: '', email: '', mot_de_passe: '', id_societe: selectedSociete.id, droit: 'superadmin' });
    setOpenUtilisateurDialog(true);
  };

  const handleCloseUtilisateurDialog = () => {
    setOpenUtilisateurDialog(false);
    setNewUtilisateur({ nom: '', email: '', mot_de_passe: '', id_societe: '', droit: 'superadmin' });
  };

  return (
    <>
      <Home />
      <Container>
        <Typography variant="h4" gutterBottom>Sociétés</Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpenSocieteDialog()}>Ajouter Société</Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Mail</TableCell>
                <TableCell>Paiement CB</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {societes.map(societe => (
                <TableRow
                  key={societe.id}
                  onClick={() => handleSocieteClick(societe)}
                  style={{ backgroundColor: selectedSociete && selectedSociete.id === societe.id ? 'lightblue' : 'inherit' }}
                >
                  <TableCell>{societe.id}</TableCell>
                  <TableCell>{societe.nom}</TableCell>
                  <TableCell>{societe.mail}</TableCell>
                  <TableCell>{societe.paiementCB ? 'Oui' : 'Non'}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleOpenSocieteDialog(societe)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="primary" onClick={(e) => { e.stopPropagation(); handleDeleteSociete(societe.id); }}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={openSocieteDialog} onClose={handleCloseSocieteDialog}>
          <DialogTitle>{selectedSociete ? 'Modifier Société' : 'Ajouter Société'}</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <TextField name="nom" label="Nom" fullWidth value={newSociete.nom} onChange={handleSocieteChange} />
            </Box>
            <Box mb={2}>
              <TextField name="mail" label="Mail" fullWidth value={newSociete.mail} onChange={handleSocieteChange} />
            </Box>
            <Box mb={2}>
              <FormControl fullWidth>
                <InputLabel>Paiement CB</InputLabel>
                <Select
                  name="paiementCB"
                  value={newSociete.paiementCB}
                  onChange={handleSocieteChange}
                >
                  <MenuItem value="Oui">Oui</MenuItem>
                  <MenuItem value="Non">Non</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSocieteDialog} color="primary">Annuler</Button>
            <Button onClick={handleSaveSociete} color="primary">{selectedSociete ? 'Modifier' : 'Ajouter'}</Button>
          </DialogActions>
        </Dialog>

        {selectedSociete && (
          <>
            <Typography variant="h5" gutterBottom>Utilisateurs de {selectedSociete.nom}</Typography>
            <Button variant="contained" color="primary" onClick={() => handleOpenUtilisateurDialog()}>Ajouter Utilisateur</Button>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Nom</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Mot de Passe</TableCell>
                    <TableCell>Droit</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedSocieteUtilisateurs.map(utilisateur => (
                    <TableRow key={utilisateur.id_utilisateur}>
                      <TableCell>{utilisateur.id_utilisateur}</TableCell>
                      <TableCell>{utilisateur.nom}</TableCell>
                      <TableCell>{utilisateur.email}</TableCell>
                      <TableCell>{utilisateur.mot_de_passe}</TableCell>
                      <TableCell>{utilisateur.droit}</TableCell>
                      <TableCell>
                        <IconButton color="primary" onClick={() => handleOpenUtilisateurDialog(utilisateur)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton color="primary" onClick={() => handleDeleteUtilisateur(utilisateur.id_utilisateur)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        <Dialog open={openUtilisateurDialog} onClose={handleCloseUtilisateurDialog}>
          <DialogTitle>{newUtilisateur.id_utilisateur ? 'Modifier Utilisateur' : 'Ajouter Utilisateur'}</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <TextField name="nom" label="Nom" fullWidth value={newUtilisateur.nom} onChange={handleUtilisateurChange} />
            </Box>
            <Box mb={2}>
              <TextField name="email" label="Email" fullWidth value={newUtilisateur.email} onChange={handleUtilisateurChange} />
            </Box>
            <Box mb={2}>
              <TextField name="mot_de_passe" label="Mot de Passe" fullWidth value={newUtilisateur.mot_de_passe} onChange={handleUtilisateurChange} />
            </Box>
            <Box mb={2}>
              <TextField name="id_societe" label="ID Société" fullWidth value={newUtilisateur.id_societe} onChange={handleUtilisateurChange} disabled />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUtilisateurDialog} color="primary">Annuler</Button>
            <Button onClick={handleUtilisateurSave} color="primary">{newUtilisateur.id_utilisateur ? 'Modifier' : 'Ajouter'}</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

export default SocietesCrud;
