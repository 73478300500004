import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, TextField, FormControlLabel, Checkbox, Box } from '@mui/material';

interface DemandeActionsProps {
  open: boolean;
  onClose: () => void;
  onValidated: () => void;
  selectedIds: number[];
  selectedEmail: string | null;
}

const DemandeActions: React.FC<DemandeActionsProps> = ({ open, onClose, onValidated, selectedIds, selectedEmail }) => {
  const [objetMail, setObjetMail] = useState(`Commandes Nº: ${selectedIds.join(', ')}`);
  const [alerte, setAlerte] = useState(false);
  const [envoyerMail, setEnvoyerMail] = useState(false);
  const [messageAppli, setMessageAppli] = useState('');
  const [messageMail, setMessageMail] = useState('');
  const formattedMessageMail = messageMail.replace(/\n/g, '<br>');

  useEffect(() => {
    if (!open) {
      setObjetMail('');
      setAlerte(false);
      setEnvoyerMail(false);
      setMessageAppli('');
      setMessageMail('');
      // Si nécessaire, réinitialisez également selectedIds et selectedEmail ici
    } else {
      // Lorsque le dialogue s'ouvre, initialisez les champs avec des valeurs spécifiques
      setObjetMail(`Commandes Nº: ${selectedIds.join(', ')}`);
      // Vous pouvez initialiser d'autres champs ici si nécessaire
    }
  }, [open, selectedIds]);

  const handleValidation = async () => {
    const dataToSend = {
      ids: selectedIds,
      email: selectedEmail,
      objet: objetMail,
      alerte,
      envoyerMail,
      messageAppli,
      messageMail: formattedMessageMail,
    };

    // Remplacez cette partie par votre logique d'envoi réelle
    console.log("Données à envoyer :", dataToSend);

    const response = await fetch('https://nodejs-admin.visamonde.com/actionsDemande', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    });

    // Gérez la réponse ici
    onClose(); // Ferme le dialogue après l'envoi
    onValidated();
  };

  const fillProblemMessage = () => {
    const problemMessage = `
Bonjour, 

Après examen de votre dossier, nous constatons que certaines informations ou pièces justificatives ne sont pas conformes. 
Afin de compléter votre dossier et de poursuivre le traitement, merci de bien vouloir apporter les modifications nécessaires. 

Détails des éléments à corriger : 
(Nom du document/information) : (Description de l'erreur ou du problème rencontré) 
(Nom du document/information) : (Description de l'erreur ou du problème rencontré) 
(Nom du document/information) : (Description de l'erreur ou du problème rencontré) 

Actions à entreprendre : Merci de bien vouloir nous faire parvenir en réponse à ce mail les éléments cités ci-dessus afin que nos équipes corrigent votre dossier et traitent votre demande.  

Pour toute question ou suivi de votre dossier, vous pouvez nous contacter sur contact@visamonde.com

Cordialement, 
L'équipe Visa Monde 
https://visamonde.com/
89 boulevard national 92250 LA GARENNE COLOMBES (FRANCE)
(+33)1 86 22 08 06
contact@visamonde.com
    `;
    setMessageMail(problemMessage);
  };

  const fillInProgressMessage = () => {
    const inProgressMessage = `
Bonne nouvelle ! Votre dossier est complet et validé par nos équipes. 

Numéro de dossier : (Numéro de dossier) 
Date de soumission : (Date du jour) 
Statut : Validé et prêt à être lancé 

Prochaines étapes : 
Lancement du dossier : Votre demande de visa pour (pays) sera lancée sous 24h (ou immédiatement si urgence sélectionnée) sur le site officiel gouvernemental. 

Suivi de progression : Mises à jour sur l'avancement de votre dossier en temps réel si une demande complémentaire est formulée par les services consulaires. Un agent vous contactera directement (rassurez-vous, cela n’arrive quasiment jamais !)

Le délai d’obtention : Le délai d’obtention est celui indiqué sur la page pays de notre site que vous pouvez consulter à tout moment. Ce délai est donné à titre informatif et peut être modifié à tout moment par les autorités consulaires

Notification d’obtention du visa : Vous recevrez un mail de confirmation une fois votre visa obtenu. Celui-ci sera disponible et téléchargeable dans l’onglet « Mes visas ». 

Nous vous remercions de votre coopération et patience tout au long de ce processus. Pour toute question ou suivi de votre dossier, vous pouvez nous contacter sur contact@visamonde.com

Cordialement, 
L'équipe Visa Monde 
https://visamonde.com/
89 boulevard national 92250 LA GARENNE COLOMBES (FRANCE)
(+33)1 86 22 08 06
contact@visamonde.com
    `;
    setMessageMail(inProgressMessage);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Actions pour les demandes sélectionnées</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="email-client"
          label="Email du client"
          type="email"
          fullWidth
          variant="outlined"
          value={selectedEmail || ''}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          margin="dense"
          id="objet-mail"
          label="Objet mail"
          type="text"
          fullWidth
          variant="outlined"
          value={objetMail}
          onChange={(e) => setObjetMail(e.target.value)}
        />
        <FormControlLabel
          control={<Checkbox checked={alerte} onChange={(e) => setAlerte(e.target.checked)} />}
          label="Alerte"
        />
        <FormControlLabel
          control={<Checkbox checked={envoyerMail} onChange={(e) => setEnvoyerMail(e.target.checked)} />}
          label="Envoyer mail"
        />
        <TextField
          margin="dense"
          id="message-appli"
          label="Message Appli"
          type="text"
          fullWidth
          variant="outlined"
          value={messageAppli}
          onChange={(e) => setMessageAppli(e.target.value)}
        />
        <TextField
          margin="dense"
          id="message-mail"
          label="Message Mail"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={messageMail}
          onChange={(e) => setMessageMail(e.target.value)}
        />
        <Box display="flex" justifyContent="center" gap={2} mt={2}>
  <Button onClick={fillProblemMessage} variant="contained" color="secondary">
    Mail Visa(s) Problème
  </Button>
  <Button onClick={fillInProgressMessage} variant="contained" color="primary">
    Mail Visa(s) en Cours
  </Button>
</Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={handleValidation} variant="contained">Valider</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DemandeActions;
