import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home'; // Assurez-vous que le chemin est correct
import Login from './components/Login'; // Assurez-vous que le chemin est correct
import DemandesScreen from './components/DemandesScreen';
import FacturationScreen from './components/FacturationScreen';
import PaysCrud from './components/PaysCrud';
import VisasCrud from './components/VisasCrud';
import UtilisateursScreen from './components/UtilisateursScreen';
import SocietesCrud from './components/SocietesCrud';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/demandes" element={<DemandesScreen />} />
        <Route path="/facturation" element={<FacturationScreen />} />
        <Route path="/pays" element={<PaysCrud />} />
        <Route path="/visas" element={<VisasCrud />} />
        <Route path="/utilisateurs" element={<UtilisateursScreen />} />
        <Route path="/societes" element={<SocietesCrud />} />
        {/* Ajoutez d'autres routes ici si nécessaire */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
