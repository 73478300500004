import axios from 'axios';

const API_URL = 'https://adminvm-gg-be.visamonde.com';

export const getSocietes = async () => {
  const response = await axios.get(`${API_URL}/societes`);
  return response.data;
};

export const createSociete = async (societe) => {
  const response = await axios.post(`${API_URL}/societes`, societe);
  return response.data;
};

export const updateSociete = async (id, societe) => {
  const response = await axios.put(`${API_URL}/societes/${id}`, societe);
  return response.data;
};

export const deleteSociete = async (id) => {
  const response = await axios.delete(`${API_URL}/societes/${id}`);
  return response.data;
};
