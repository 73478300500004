import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, Container, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';


const BackgroundImageContainer = styled('div')({
  backgroundImage: 'url(https://beta.visamonde.com/assets/mobile/fond.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState(false); // Ajout d'un état pour gérer les erreurs de connexion


  const handleLogin = () => {
    if (username === 'admin' && password === 'visamonde') {
      navigate('/demandes'); // Navigation vers les demandes si les identifiants sont corrects
      setError(false); // Réinitialisation de l'état d'erreur
    } else {
      setError(true); // Affichage d'un message d'erreur si les identifiants sont incorrects
    }
  };

  return (
    <BackgroundImageContainer>
      <Container maxWidth="sm">
        <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h5" style={{ marginBottom: '20px' }}>Admin Visa Monde Société</Typography>
          <Typography variant="h5" style={{ marginBottom: '20px' }}>Connexion</Typography>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              required
              id="username"
              label="Nom d'utilisateur"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              required
              id="password"
              label="Mot de passe"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
             {error && (
              <Alert severity="error" style={{ margin: '10px' }}>
                Identifiants incorrects, veuillez réessayer.
              </Alert>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 1 }}>
              <Button variant="contained" onClick={handleLogin}>Se connecter</Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </BackgroundImageContainer>
  );
};

export default Login;
