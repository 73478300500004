import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { recupereBDC } from '../Services';
import BonDeCommande from '../models/BonDeCommande';
import DemandesDuBDC from './DemandesDuBDC';

interface ListeBDCProps {
  setBonDeCommandeSelectionne: (bonDeCommande: BonDeCommande) => void;
  refreshKey: number; 
}


const ListeBDC: React.FC<ListeBDCProps> = ({ setBonDeCommandeSelectionne, refreshKey }) => {
  const [bonsDeCommande, setBonsDeCommande] = useState<BonDeCommande[]>([]);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const fetchBDC = async () => {
    try {
      const bdc = await recupereBDC();
      setBonsDeCommande(bdc);
    } catch (error) {
      console.error('Erreur lors de la récupération des bons de commande:', error);
    }
  };
  
  useEffect(() => {
    fetchBDC();
  }, [refreshKey]);
  

  const handleRowClick = (id: number) => {
    setSelectedId(id);
    const bonDeCommande = bonsDeCommande.find(bdc => bdc.id === id);
    if (bonDeCommande) {
      setBonDeCommandeSelectionne(bonDeCommande);
    }
  };

  return (
    <div>
      <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Date d'émission</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>État</TableCell>
            <TableCell>Date paiement</TableCell>
            <TableCell>Envoi Facture</TableCell>
            <TableCell>ID Stripe</TableCell>
            {/* Add other headers as necessary */}
          </TableRow>
        </TableHead>
        <TableBody>
          {bonsDeCommande.map((bdc) => (
            <TableRow
              key={bdc.id}
              onClick={() => handleRowClick(bdc.id)}
              hover
              style={{
                backgroundColor: selectedId === bdc.id ? '#add8e6' : 'inherit',
                color: selectedId === bdc.id ? 'white' : 'inherit',
              }}
            >
              <TableCell>{bdc.id}</TableCell>
              <TableCell>{new Date(bdc.date_emission).toLocaleDateString()}</TableCell>
              <TableCell>{bdc.total}</TableCell>
              <TableCell>{bdc.etat}</TableCell>
              <TableCell>
              {bdc.date_paiement ? new Date(bdc.date_paiement).toLocaleDateString() : ''}
              </TableCell>
              <TableCell>{bdc.envoie_facture}</TableCell>
              <TableCell>{bdc.id_stripe}</TableCell>
              {/* Add other cells as necessary */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      {selectedId && <DemandesDuBDC idBonDeCommande={selectedId} />}
    </div>
  );
};

export default ListeBDC;
