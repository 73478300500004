import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TextField, MenuItem } from '@mui/material';
import { recupereUtilisateurs } from '../Services'; // Assurez-vous que cette fonction est correctement implémentée pour récupérer les utilisateurs
import { Utilisateur } from '../models/Utilisateur';
import Home from './Home';

const UtilisateursScreen = () => {
    const [utilisateurs, setUtilisateurs] = useState<Utilisateur[]>([]);
    const [filteredUtilisateurs, setFilteredUtilisateurs] = useState<Utilisateur[]>([]);
    const [societeFilter, setSocieteFilter] = useState('');
    const [groupeFilter, setGroupeFilter] = useState('');
    const [nameSearch, setNameSearch] = useState('');
    const [emailSearch, setEmailSearch] = useState('');

    const fetchUtilisateurs = async () => {
        try {
            const users = await recupereUtilisateurs();
            setUtilisateurs(users);
            setFilteredUtilisateurs(users);
        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs:', error);
        }
    };

    useEffect(() => {
        fetchUtilisateurs();
    }, []);

    useEffect(() => {
        filterUtilisateurs();
    }, [societeFilter, groupeFilter, nameSearch, emailSearch]);

    const filterUtilisateurs = () => {
        let filtered = utilisateurs;
        if (societeFilter) {
            filtered = filtered.filter(user => user.societe === societeFilter);
        }
        if (groupeFilter) {
            filtered = filtered.filter(user => user.groupe === groupeFilter);
        }
        if (nameSearch) {
            filtered = filtered.filter(user => user.nom.toLowerCase().includes(nameSearch.toLowerCase()));
        }
        if (emailSearch) {
            filtered = filtered.filter(user => user.email.toLowerCase().includes(emailSearch.toLowerCase()));
        }
        setFilteredUtilisateurs(filtered);
    };

    const uniqueSocietes = Array.from(new Set(utilisateurs.map(user => user.societe)));
    const uniqueGroupes = Array.from(new Set(utilisateurs.map(user => user.groupe)));

    return (
        <>
            <Home />
            <Typography variant="h6" style={{ margin: '20px 0', marginLeft: '15px' }}>
                Nombre de Clients: {filteredUtilisateurs.length}
            </Typography>

            <div style={{ display: 'flex', gap: '20px', marginLeft: '15px', marginBottom: '20px' }}>
                <TextField
                    label="Recherche par Nom"
                    value={nameSearch}
                    onChange={(e) => setNameSearch(e.target.value)}
                    style={{ minWidth: '200px' }}
                />
                <TextField
                    label="Recherche par Email"
                    value={emailSearch}
                    onChange={(e) => setEmailSearch(e.target.value)}
                    style={{ minWidth: '200px' }}
                />
                <TextField
                    select
                    label="Filtrer par Société"
                    value={societeFilter}
                    onChange={(e) => setSocieteFilter(e.target.value)}
                    style={{ minWidth: '200px' }}
                >
                    <MenuItem value="">
                        <em>Toutes</em>
                    </MenuItem>
                    {uniqueSocietes.map((societe) => (
                        <MenuItem key={societe} value={societe}>
                            {societe}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    label="Filtrer par Groupe"
                    value={groupeFilter}
                    onChange={(e) => setGroupeFilter(e.target.value)}
                    style={{ minWidth: '200px' }}
                >
                    <MenuItem value="">
                        <em>Tous</em>
                    </MenuItem>
                    {uniqueGroupes.map((groupe) => (
                        <MenuItem key={groupe} value={groupe}>
                            {groupe}
                        </MenuItem>
                    ))}
                </TextField>
            </div>

            <TableContainer component={Paper} style={{ maxHeight: '800px' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Nom</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Date d'inscription</TableCell>
                            <TableCell>Société</TableCell>
                            <TableCell>Groupe</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUtilisateurs.map((user) => (
                            <TableRow key={user.id_utilisateur}>
                                <TableCell>{user.id_utilisateur}</TableCell>
                                <TableCell>{user.nom}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{new Date(user.date_inscription).toLocaleDateString()}</TableCell>
                                <TableCell>{user.societe}</TableCell>
                                <TableCell>{user.groupe}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default UtilisateursScreen;
