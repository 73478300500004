import React, { useState, useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, useMediaQuery, useTheme, FormControl, InputLabel, Select, MenuItem, DialogContentText } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Home from './Home';

interface Pays {
  id: string;
  libelle: string;
  url_flag: string;
  url_image: string;
  url_infos: string;
  visible: string;
}


const PaysCrud = () => {
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const [pays, setPays] = useState<Pays[]>([]); // Use the Pays interface here
  const [open, setOpen] = useState(false);
  const [currentPays, setCurrentPays] = useState({ id: '', libelle: '', url_flag: '', url_image: '', url_infos: '', visible: '' });
  const [isEdit, setIsEdit] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const handleOpenConfirm = (id:any) => {
    setOpenConfirm(true);
    setDeleteId(id);
  };
  

  useEffect(() => {
    fetchPays();
  }, []);

  const fetchPays = async () => {
    // Utilisez l'URL de votre API pour récupérer les données
    const response = await fetch('https://adminvm-gg-be.visamonde.com/pays');
    const data = await response.json();
    setPays(data);
  };

  const handleOpen = (pays: Pays | null = null) => {
    if (pays) {
      setCurrentPays(pays);
      setIsEdit(true);
    } else {
      setCurrentPays({ id: '', libelle: '', url_flag: '', url_image: '', url_infos: '', visible: ''});
      setIsEdit(false);
    }
    setOpen(true);
  };
  

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setCurrentPays({ ...currentPays, [name]: value });
  };

  const handleSubmit = async () => {
    const method = isEdit ? 'PUT' : 'POST';
    const endpoint = isEdit ? `https://nodejs-admin.visamonde.com/pays/${currentPays.id}` : 'https://nodejs-admin.visamonde.com/pays';

    await fetch(endpoint, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(currentPays),
    });

    fetchPays();
    handleClose();
  };

  const handleDeleteConfirmed = async () => {
    if (deleteId) {
      await fetch(`https://nodejs-admin.visamonde.com/pays/${deleteId}`, {
        method: 'DELETE',
      });
  
      fetchPays();
      setDeleteId(null);
      setOpenConfirm(false); // Fermez la modale de confirmation après la suppression
    }
  };
  

  return (
    <>
      <Home />
      <Button
      variant="contained"
      color="primary"
      onClick={() => handleOpen()}
      sx={{ marginTop: 2, marginBottom: 2, marginLeft: 2, marginRight: 2 }}
    >
      Ajouter un pays
    </Button>

      <Paper sx={{ margin: 2, padding: 2 }}>
        <Grid container spacing={2}>
        <Grid container spacing={2} sx={{ mb: 1 }}>
      <Grid item xs={12} sm={4}><b>Libellé</b></Grid>
      <Grid item xs={6} sm={2}><b>Flag URL</b></Grid>
      <Grid item xs={6} sm={2}><b>Image URL</b></Grid>
      <Grid item xs={6} sm={2}><b>Infos URL</b></Grid>
      <Grid item xs={12} sm={1}><b>Visible</b></Grid>
      <Grid item xs={12} sm={1}><b>Actions</b></Grid>
    </Grid>
          {pays.map((row, index) => (
            <React.Fragment key={row.id}>
              {index > 0 && <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider' }} />}
              <Grid container spacing={2} sx={{ '&:hover': { backgroundColor: 'action.hover' }, padding: 1 }}>
                <Grid item xs={12} sm={4}>{row.libelle}</Grid>
                <Grid item xs={6} sm={2} sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{row.url_flag}</Grid>
                <Grid item xs={6} sm={2} sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{row.url_image}</Grid>
                <Grid item xs={6} sm={2} sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{row.url_infos}</Grid>
                <Grid item xs={12} sm={1} sx={{ display: 'flex', alignItems: 'center' }}>{row.visible }</Grid>
                <Grid item xs={12} sm={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton onClick={() => handleOpen(row)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleOpenConfirm(row.id)}>
                <DeleteIcon />
              </IconButton>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Paper>
  
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{isEdit ? 'Modifier le pays' : 'Ajouter un pays'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="libelle"
            name="libelle"
            label="Libellé"
            type="text"
            fullWidth
            variant="outlined"
            value={currentPays?.libelle}
            onChange={handleChange}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            margin="dense"
            id="url_flag"
            name="url_flag"
            label="Flag URL"
            type="text"
            fullWidth
            variant="outlined"
            value={currentPays?.url_flag}
            onChange={handleChange}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            margin="dense"
            id="url_image"
            name="url_image"
            label="Image URL"
            type="text"
            fullWidth
            variant="outlined"
            value={currentPays?.url_image}
            onChange={handleChange}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            margin="dense"
            id="url_infos"
            name="url_infos"
            label="Infos URL"
            type="text"
            fullWidth
            variant="outlined"
            value={currentPays?.url_infos}
            onChange={handleChange}
            sx={{ marginBottom: 2 }}
          />
          <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
          <InputLabel id="visible-label">Visible</InputLabel>
          <Select
            labelId="visible-label"
            id="visible"
            name="visible"
            value={currentPays?.visible}
            onChange={handleChange}
            label="Visible"
          >
            <MenuItem value="oui">Oui</MenuItem>
            <MenuItem value="non">Non</MenuItem>
          </Select>
        </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleSubmit}>{isEdit ? 'Modifier' : 'Ajouter'}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
  open={openConfirm}
  onClose={() => setOpenConfirm(false)}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"Confirmez la suppression"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      Êtes-vous sûr de vouloir supprimer ce pays ?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenConfirm(false)} color="primary">
      Annuler
    </Button>
    <Button onClick={handleDeleteConfirmed} color="primary" autoFocus>
      Confirmer
    </Button>
  </DialogActions>
</Dialog>

    </>
  );
  
  
};

export default PaysCrud;

