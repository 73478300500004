import React, { useEffect, useState } from 'react';
import { TextField, Paper } from '@mui/material';
import { recupererDemandesBDC } from '../Services';

interface DemandesDuBDCProps {
  idBonDeCommande: number;
}

const DemandesDuBDC: React.FC<DemandesDuBDCProps> = ({ idBonDeCommande }) => {
  const [demandes, setDemandes] = useState<number[]>([]);

  useEffect(() => {
    const fetchDemandes = async () => {
      try {
        const demandesDuBDC = await recupererDemandesBDC(idBonDeCommande);
        console.log('ici ' + demandesDuBDC);
        setDemandes(demandesDuBDC);
      } catch (error) {
        console.error('Erreur lors de la récupération des demandes pour le bon de commande:', error);
      }
    };

    fetchDemandes();
  }, [idBonDeCommande]);

  // Convertir le tableau en chaîne de caractères pour affichage
  const demandesTexte = demandes.join(', ');

  return (
    <Paper style={{ padding: 20 }}>
      <TextField
        fullWidth
        label="Numéros de Demandes"
        variant="outlined"
        multiline
        rows={2}
        value={demandesTexte}
        InputProps={{
          readOnly: true,
        }}
      />
    </Paper>
  );
};

export default DemandesDuBDC;
